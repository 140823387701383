import React from "react";
import { Upload, message, Spin, Button } from "antd";
import Uppy from "@uppy/core";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import { CloudUploadOutlined } from '@ant-design/icons';



const storage_host = 'https://storage-stg.revtel-api.com/v3';
async function post({ path, data }) {
  let response = await fetch(`${storage_host}${path}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (`${response.status}`[0] === "2") {
    return response.json();
  }

  return Promise.reject({ status: response.status });
}


export default class MultipartUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      url: "",
    }

    this.jwt_token = props.token;

    // uppy inital
    this.uppy = new Uppy({
      debug: true,
    });
  }

  componentDidMount() {
    let params = {
      bucket: "",
      uploadId: "",
      key: "",
    };

    // uppy use plugin "AwsS3Multipart" and configuration
    this.uppy.use(AwsS3Multipart, {
      getChunkSize: file => 1024 * 1024 * 5, // 5MB
      createMultipartUpload: async file => {
        let result = await post({
          path: `/storage/multipart/create?token=${this.jwt_token}`,
          data: {
            key: file.name,
          },
        });
        //{ Bucket, UploadId, Key, ResponseMetadata }
        params = {
          bucket: result.Bucket,
          uploadId: result.UploadId,
          key: result.Key,
        };
        return params;
      },
      prepareUploadPart: async (file, partData) => {
        let result = await post({
          path: `/storage/presigned/upload-part?token=${this.jwt_token}`,
          data: {
            bucket: params.bucket,
            key: partData.key,
            upload_id: partData.uploadId,
            part_number: partData.number,
          },
        });

        return result;
      },
      abortMultipartUpload: async (file, { uploadId, key }) => {
        let result = await post({
          path: `/storage/multipart/abort?token=${this.jwt_token}`,
          data: {
            bucket: params.bucket,
            upload_id: uploadId,
            key,
          },
        });
      },
      completeMultipartUpload: async (file, { uploadId, key, parts }) => {
        let result = await post({
          path: `/storage/multipart/complete?token=${this.jwt_token}`,
          data: {
            upload_id: uploadId,
            key,
            parts,
          },
        });

        this.setState({ url: result.expected });
        console.log("SUCCESSFUL FILE PATH:", result.expected)

        return result;
      },

      listParts: async (file, { uploadId, key }) => {
        let result = await post({
          path: `/storage/multipart/list?token=${this.jwt_token}`,
          data: {
            upload_id: uploadId,
            key,
          },
        });

        return result;
      },
    });

    // event listener: progress
    this.uppy.on("upload-progress", (file, progress) => {
      console.log("PROGRESS", (
        (progress.bytesUploaded / progress.bytesTotal) *
        100
      ).toFixed())
    });

    // event listener: start upload
    this.uppy.on("upload", () => {
      console.log("START UPLOAD")
    });

  }

  componentWillUnmount() {
    this.uppy.off("upload-progress", () => {});
    this.uppy.off("upload", () => {});
    this.uppy.close();
  }

  render() {
    let { loading, url } = this.state;
    return (
      <Spin spinning={loading}>
        <Upload
          fileList={[]}
          name="file"
          className="avatar-uploader"
          beforeUpload={file => false}
          onChange={this._upload}
        >
      <div>

          <CloudUploadOutlined/>
            <div style={{ marginTop: 8 }}>上傳大型檔案</div>
            </div>
        </Upload>
        { url }
      </Spin>
    );
  }

  _upload = async info => {
    this.setState({ loading: true });
    try {
      let file = info.file; // structure from Ant.Upload return Object

      // step 1: get JWT token
      // skip, because here token is from props

      // step 2 add file to uppy
      let fileId = this.uppy.addFile({
        source: "file input",
        name: file.name,
        type: file.type,
        data: file,
      });

      // step 3 upload by uppy
      let result = await this.uppy.upload();
      console.log("RETURN FROM UPLOAD", result);

      if (result) {
        if (result.successful) {
          message.success("上傳成功");
        } else {
          console.warn("Errors: ", result.failed);
          message.error("上傳失敗");
        }

        // step 4: attach file to any instance
        // await Cart.Actions.addAttatchment({
        //   order: orderItem.order,
        //   item_index: orderItem.item_index,
        //   url: this.state.url,
        // });

      }
    } catch (err) {
      console.warn(err);
    }

    this._reset();
  };

  _reset = () => {
    this.uppy.cancelAll();
    //cancel upload and remove file

    this.setState({ loading: false });
    // or reset other state of component
  };
}