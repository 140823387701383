import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { GoogleLogin } from 'react-google-login';
import MultipartUploader from "./MultipartUploader";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class Avatar extends React.Component {
  state = {
    loading: false,
    url: null,
    data: {},
    filename: null,
  };
  beforeUpload = async file => {
  let resp = await fetch(`https://storage-stg.revtel-api.com/v3/storage/presigned/url?token=${this.props.token}`, {
  method: 'POST',
  body: JSON.stringify({'acl': 'public-read', 'Content-Type': file.type, 'key': file.name}),
  headers: {'Content-Type': 'application/json'}         
  })
  resp = await resp.json()
  this.setState({url: resp.url, data: resp.fields, filename: resp.expected})
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  render() {
    const { loading, imageUrl, filename } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
    <>
      <Upload
        name='file'
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={this.state.url}
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        data={this.state.data}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '50%' }} /> : uploadButton}
      </Upload>
        {filename? <p>{filename}</p>: null}
    </>
    );
  }
}

class App extends React.Component {
  state = {
    auth: false,
    token: null,
    name: null
  }
  onSuccess =async (resp) => {
    console.log(resp)
    let response = await fetch('https://internal.revtel2.com/auth/google', {
    method: 'POST',
    body: JSON.stringify({token: resp.tokenId}),
    headers: {'Content-Type': 'application/json'}
})
    response = await response.json()
    this.setState({auth: true, token: response.token, name:response.name})
  }
  render(){
    return (
    <div className="App">
      <header className="App-header">
      <h1>REVTEL STORAGE</h1>
      {!this.state.auth && (
        <GoogleLogin
          clientId="782455260604-925fnvgriavsgqs89edlecqcgfee8d49.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={this.onSuccess}
          onFailure={ resp => console.log(resp)}
          cookiePolicy={'single_host_origin'}
        />
      )}
      {this.state.auth && (
        <div style={{ display: 'flex' }}>
          <div className="button">
            <Avatar token={this.state.token}/>
          </div>
          <div className="button" style={{ marginLeft: 10 }}>
            <MultipartUploader token={this.state.token}/>
          </div>
        </div>
      )}
      </header>
    </div>
  );}
}



export default App;
